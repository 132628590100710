var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sm:text-sm dark--text d-flex align-center justify-center",
      style: `${_vm.styles.height}; ${_vm.styles.width}`,
    },
    [_vm._v(" " + _vm._s(_vm.message) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }