<template>
  <v-container class="timer d-flex align-center justify-center">
    <h2>{{ formattedTime }}</h2>
  </v-container>
</template>

<script>
export default {
  name: "TimeDisplay",
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    formattedTime() {
      const seconds = (this.value % 60) + "";
      let remaining = Math.trunc(this.value / 60);
      const minutes = (remaining % 60) + "";
      const hours = Math.trunc(remaining / 60) + "";

      return `${hours.padStart(2, "0")}:${minutes.padStart(
        2,
        "0",
      )}:${seconds.padStart(2, 0)}`;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss">
.timer {
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  h2 {
    font-size: 27px;
    font-weight: 500;
    color: $text;
  }
}
</style>
