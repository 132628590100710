// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".q-nav {\n  background: #ffffff;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);\n  border-radius: 10px;\n}\n.q-nav p {\n  font-size: 15px;\n}\n.q-nav .q-nav__items {\n  overflow-y: scroll;\n  display: grid;\n  grid-template-columns: auto auto auto auto;\n  justify-content: space-around;\n}\n.q-nav .q-nav__items .round-counter-nav {\n  width: 35px;\n  height: 35px;\n  border-radius: 50%;\n  background-color: #c3c3c3;\n}\n.q-nav .q-nav__items .round-counter-nav p {\n  font-size: 16px;\n  font-weight: normal;\n  text-align: center;\n  color: #000000;\n}\n.q-nav .q-nav__items .round-counter-nav.selected {\n  background-color: #0099dc;\n}\n.q-nav .q-nav__items .round-counter-nav.selected p {\n  font-size: 16px;\n  font-weight: normal;\n  color: #ffffff !important;\n}\n.q-nav .q-nav__items .round-counter-nav.corrected {\n  background-color: #00796b;\n}\n.q-nav .q-nav__items .round-counter-nav.corrected p {\n  font-size: 16px;\n  font-weight: normal;\n  color: #ffffff !important;\n}\n.q-nav .q-nav__items .round-counter-nav.wronged {\n  background-color: #ff5252;\n}\n.q-nav .q-nav__items .round-counter-nav.wronged p {\n  font-size: 16px;\n  font-weight: normal;\n  color: #ffffff !important;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
