<template>
  <div
    :style="`${styles.height}; ${styles.width}`"
    class="sm:text-sm dark--text d-flex align-center justify-center"
  >
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "NoContentComponent",
  props: {
    message: {
        type: String,
        default: "There are no items"
    },
    height: {
        type: Number,
        default: undefined,
    },
    width: {
        type: Number,
        default: undefined
    }    
  },
  computed: {
    styles(){
        return {
            height: this.height ? `height: ${this.height}px` : 'height: 200px',
            width: this.width ? `width: ${this.width}px` : 'width: 100%'
        }
    },
  }
};
</script>