// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".timer {\n  height: 50px;\n  background: #ffffff;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);\n  border-radius: 10px;\n}\n.timer h2 {\n  font-size: 27px;\n  font-weight: 500;\n  color: #3d3d3d;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
