import { Logger } from "/global/utils/helpers";
import Swal from "sweetalert2";
import alertIcon from "/global/assets/alertDialogIcons/Icon_alert.svg";

export const ExamProvider = {
  props: {
    examId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fetchingAllExams: false,
      fetchingExam: {}
    };
  },
  computed: {
    exams() {
      return this.$store.getters["studentExam/exams"];
    },
    exam() {
      return this.examId
        ? this.$store.state.studentExam.exams[this.examId]
        : undefined;
    }
  },
  methods: {
    async listExams() {
      try {
        await this.$store.dispatch("studentExam/list");
      } catch (e) {
        Logger.log(e);
        Swal.fire({
          title: "Alert",
          text: e,
          imageUrl: alertIcon
        });
      }
    },
    async getExam() {
      try {
        return await this.$store.dispatch("studentExam/get", {
          id: this.examId,
          batchId: this.$ielts_batch_id
        });
      } catch (e) {
        Logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async getExamDetails() {
      try {
        return await this.$store.dispatch("studentExam/getDetails", {
          id: this.examId,
          batchId: this.$ielts_batch_id
        });
      } catch (e) {
        Logger.log(e);
        // this.$root.$emit("alert", [undefined, e.message]);
        if (e.cause !== undefined) {
          this.$root.$emit("alert-access", {
            message: e.message,
            cause: e.cause,
            url: window.location.href
          });
        } else {
          this.$root.$emit("alert", [undefined, e.message]);
        }
      }
    }
  }
};

export const ExamPackageProvider = {
  props: {
    examPackageId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fetchingAllExamPackages: false,
      fetchingExamPackage: {}
    };
  },
  computed: {
    examPackages() {
      return this.$store.getters["examPackage/packages"];
    },
    examPackage() {
      return this.examPackageId
        ? this.$store.state.examPackage.packages[this.examPackageId]
        : undefined;
    }
  },
  methods: {
    async listExamPackages() {
      try {
        await this.$store.dispatch("studentExamPackage/list");
      } catch (e) {
        Logger.log(e);
        Swal.fire({
          title: "Alert",
          text: JSON.stringify(e.response ? e.response.data : e),
          imageUrl: alertIcon
        });
      }
    },
    async getExamPackage(id) {
      try {
        return await this.$store.dispatch("studentExamPackage/get", {
          packageId: id
        });
      } catch (e) {
        Logger.log(e);
        Swal.fire({
          title: "Alert",
          text: JSON.stringify(e.response ? e.response.data : e),
          imageUrl: alertIcon
        });
      }
    }
  }
};

export const ExamManager = {
  props: {
    examPackageId: String,
    examId: String
  },
  methods: {
    async startExam() {
      return this.$store.dispatch("studentExam/start", {
        examId: this.examId,
        packageId: this.examPackageId
      });
    },
    async getQuestions(categoryId) {
      return this.$store.dispatch("studentExam/getQuestions", { categoryId });
    },
    async recordAnswer(questionId, answerId) {}
  }
};

export const EquationFinder = {
  data() {
    return {};
  },
  methods: {
    async retrieveEquationsAndModifyQuestions(questions, examId) {
      try {
        for (let question of questions) {
          let resp = await this.findEquationsFromQuestionText(
            question.item_text,
            question.id,
            examId
          );
          question.item_text = resp;
          this.renderNow = true;
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async findEquationsFromQuestionText(text, questionId, examId) {
      if (text === undefined) return text;

      const domObject = new DOMParser().parseFromString(text, "text/html");
      let equationPlaceholders = domObject.querySelectorAll(".eq-placeholder");
      if (equationPlaceholders.length == 0) return text;

      for (let [index, item] of equationPlaceholders.entries()) {
        let equationText = await this.$store.dispatch(
          "studentExam/getEquationForQuestion",
          {
            examId,
            questionId,
            index
          }
        );
        let tempElement = document.createElement("div");
        tempElement.innerHTML = await this.blobToString(equationText);
        item.parentElement.replaceChild(tempElement.firstElementChild, item);
      }

      return domObject.body.innerHTML;
    },
    // This should probably be in utils/helpers.js
    // Since hasan said it's on his branch so I didn't put it in utils
    async blobToString(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = event => {
          resolve(event.target.result);
        };
        reader.readAsText(blob);
      });
    }
  }
};
