var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "timer d-flex align-center justify-center" },
    [_c("h2", [_vm._v(_vm._s(_vm.formattedTime))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }